import http from "../axios-instance";

export class EcuAdblueLocationsService {
  static async addLocation(model) {
    try {
      const formModel = new FormData();
      Object.entries(model).forEach(([name, value]) => {
        if (Array.isArray(model[name]))
          value.forEach((file) => formModel.append(name, file));
        else formModel.append(name, model[name]);
      });

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const res = await http.post(
        "/vehicle/ecu-adblue-location",
        formModel,
        config
      );
      return await Promise.resolve(res.data);
    } catch (err) {
      return await Promise.reject(err);
    }
  }

  static async updateLocation({
    _id,
    ecu_location,
    ecu_location_images,
    adblue_location,
    adblue_location_images,
  }) {
    try {
      const formModel = new FormData();
      formModel.append("_id", _id);
      formModel.append("ecu_location", ecu_location);
      formModel.append("adblue_location", adblue_location);
      ecu_location_images.forEach((file) =>
        formModel.append("ecu_location_images", file)
      );
      adblue_location_images.forEach((file) =>
        formModel.append("adblue_location_images", file)
      );

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const res = await http.put(
        "/vehicle/ecu-adblue-location/" + _id,
        formModel,
        config
      );
      return await Promise.resolve(res.data);
    } catch (err) {
      return await Promise.reject(err);
    }
  }

  static async getAllLocations(params) {
    try {
      const res = await http.get("/vehicle/ecu-adblue-locations", {
        params: params,
      });
      return await Promise.resolve(res.data);
    } catch (err) {
      return await Promise.reject(err);
    }
  }

  static async getOneLocation({
    vtype,
    manufacture,
    vmodel,
    generation,
    variant,
  }) {
    try {
      const res = await http.get(`/vehicle/one-ecu-adblue-location`, {
        params: { vtype, manufacture, vmodel, generation, variant },
      });
      return await Promise.resolve(res.data);
    } catch (err) {
      return await Promise.reject(err);
    }
  }

  static async deleteLocation(id) {
    try {
      const res = await http.delete(`/vehicle/ecu-adblue-location/${id}`);
      return await Promise.resolve(res.data);
    } catch (err) {
      return await Promise.reject(err);
    }
  }
}
