import http from "../axios-instance";

class RegUvcFinalDataService {
  createUvcFinalData(formData) {
    return http
      .post("/reg-search/uvc-final", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getUvcFinalData(params) {
    return http
      .get("/reg-search/uvc-final", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  getTotalUvcMakes() {
    return http
      .get("/reg-search/uvc-final/total-makes")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateUvcFinalData(id, data) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return http
      .put(`/reg-search/uvc-final/${id}`, data, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteUvcFinalAllData() {
    return http
      .delete(`/reg-search/uvc-final/all`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  deleteUvcFinal(id) {
    return http
      .delete(`/reg-search/uvc-final/${id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new RegUvcFinalDataService();
